.menu-burger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &--label {
    font-size: 9px;
    color: var(--theme-font-color);
    user-select: none;
  }

  .fa-light {
    font-size: 20px;
  }

  .menu-burger-btn {
    display: flex;
    flex-direction: column;
  }
}
