@use '../../../../stories/scss/settings/spacing';
@use '../../../variable/transition';

.searchBox {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: transform transition.$default, opacity transition.$default;
  transform: translate(0, 38px) scale(0);

  .has-sticky & {
    opacity: 1;
    transition: transform transition.$default, opacity transition.$default;
    pointer-events: all;
    transform: translate(0, 0) scale(1);
  }
}
