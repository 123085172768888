@use '../../../overlay/overlay-base';

@use '../../../../variable/breakpoint';
@use '../../../../variable/font';
@use '../../../../variable/components/header';
@use '../../../../../stories/scss/settings/spacing';

body [id] {
  scroll-margin-top: calc(#{header.$mobileHeaderHeight} + #{spacing.$s2});
}

.main-nav {
  $nav: &;
  padding-bottom: spacing.$s6;

  &__right {
    border-top: 1px solid var(--theme-color-base);
    padding-top: spacing.$s6;
  }

  &__header {
    position: sticky;
    top: 0;
    width: 100%;
    margin-bottom: spacing.$s6;

    &__first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: spacing.$s4;
      border-bottom: 1px solid var(--theme-color-base);
      background-color: var(--theme-color-background);
    }

    &--language {
      display: none;
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: transparent;
      border: 0;
      width: 28px;
      height: 28px;
      padding: 0;

      cursor: pointer;

      i {
        font-size: 16px;
      }
    }

    &-title {
      font-family: font.$secondary;
      font-size: font.$size-sm;
    }

    &__second {
      padding: spacing.$s8 spacing.$s4;

      &.is-open {
        transform: translate(0);
      }

      &-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        font-family: font.$primary;
        font-size: font.$size-base;
        color: var(--theme-font-color);
      }
    }
  }

  &-list {
    margin: 0 0 spacing.$s6;
    padding: 0;
    list-style: none;

    &--country {
      padding-top: spacing.$s6;
      border-top: 1px solid var(--theme-color-base);
    }

    &:hover {
      &:after {
        @extend .overlay;
      }
    }
  }

  &-item {
    &--parent {
      &:hover {
        &:after {
          @extend .overlay;
        }
      }
    }

    &--account,
    &--language {
      display: block;
    }

    &--title {
      padding: 0 spacing.$s4 10px;
    }

    &--country {
      margin-bottom: spacing.$s6;
    }
  }

  &-title {
    font-family: font.$secondary;
    font-size: font.$size-xs;
    margin: 0;
  }

  &-link {
    display: flex;
    justify-content: space-between;
    align-items: center;

    text-align: left;
    cursor: pointer;
    width: 100%;
    height: 46px;

    background-color: var(--theme-color-background);
    border: none;
    padding: 0 spacing.$s4;

    text-decoration: none;
    font-family: font.$primary;
    font-size: font.$size-base;

    // For flag image
    img {
      height: 16px;
      margin-right: spacing.$s2;
      border-radius: var(--theme-border-radius-xs);
    }

    i {
      margin-left: auto;
      transform: rotate(-90deg);
    }

    &:hover {
      text-decoration: none;
    }

    &-name {
      display: block;
    }
  }

  /**
  * Dropdown
  * This is the first layer and visible in the header
  */
  &__dropdown {
    position: absolute;
    transform: translate(100%, 0);
    top: 0;

    &--first {
      padding-top: 61px;
      height: 100%;
      width: 100%;
      background-color: var(--theme-color-background);
      overflow-y: auto;
      overflow-x: hidden;
      transition: transform 0.4s;
      transition-timing-function: cubic-bezier(0.1, 0, 0.1, 1);

      &.is-open {
        transform: translate(0, 0);
      }
    }

    &--country {
      padding-top: 48px;
    }

    &--second {
      position: static;
      transform: translate(0, 0);
      width: 100%;
    }

    &--box {
      position: static;
      transform: none;
      width: 100%;
    }
  }

  /**
    * Second level navigation
    * This is the left sidebar in the dropdown
    */

  &__second {
    $nav-second: &;
    border-top: 1px solid var(--theme-color-base);
    padding-top: spacing.$s6;

    &--language {
      border-top: 0;
    }

    &-list {
      margin: 0 0 spacing.$s6;
      padding: 0;
      list-style: none;
    }

    &-link {
      display: flex;
      justify-content: space-between;
      align-items: center;

      text-align: left;
      cursor: pointer;
      width: 100%;
      height: 46px;

      background-color: var(--theme-color-background);
      border: none;
      padding: 0 spacing.$s4;

      color: var(--theme-font-color);
      font-size: font.$size-base;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      #{ $nav-second }--language & {
        justify-content: flex-start;
      }

      img {
        height: 16px;
        margin-right: 8px;
        border-radius: var(--theme-border-radius-xs);
      }
    }
  }

  /**
  * Third level navigation
  * This is the third layer and as content in the dropdown if you hover over the sidebar
  */

  &__third {
    $nav-third: &;
    padding: spacing.$s8 spacing.$s4;
    background-color: var(--theme-color-white);

    &__header {
      display: none;
    }

    > div {
      margin: 0 0 spacing.$s4;
      list-style: none;

      &:first-of-type .nav-link-title {
        padding-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .nav-link-title {
      line-height: 28px;
      font-weight: 600;
      color: var(--theme-font-color);
      padding: 0;
    }

    a {
      display: block;
      padding: 5px 0;
    }
  }

  /**
    * Fourth level navigation
    * Used to display different languages for some countries
    */

  &__fourth {
    padding: spacing.$s4;
    background-color: var(--theme-color-white);

    &-list {
      margin: 10px 0 0;
      padding: 10px 0 0;

      list-style: none;
      border-top: 1px solid var(--theme-color-base);

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &-item {
      &:not(:last-child){
        margin-bottom: spacing.$s4;
      }
    }

    &-title {
      margin: 0 0 4px;
      line-height: 28px;
      font-size: font.$size-base;
      font-family: font.$primary;
      color: var(--theme-font-color);
    }

    &-link {
      display: block;
      padding: 0;
      color: var(--theme-font-color);
    }
  }
}
