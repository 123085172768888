// Variable
@use '../../../variable/breakpoint';
@use '../../../variable/color';
@use '../../../variable/font';
@use '../../../../stories/scss/settings/spacing';

.countdown {
  display: flex;
  align-items: center;
  margin-top: spacing.$s2;
  justify-content: center;

  .popup & {
    margin-top: spacing.$s4;
  }

  @media screen and (min-width: breakpoint.$lg) {
    margin-top: 0;
    margin-left: spacing.$s4;

    .popup & {
      margin-left: 0;
    }
  }

  &--hidden {
    display: none;
  }

  &__time {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: calc(#{spacing.$s2} * 0.5);
    width: 18px;
    height: 20px;
    padding-top: 2px;

    background-color: var(--theme-color-white);
    border-radius: var(--theme-border-radius-xs);
    font-size: font.$size-xxs;

    &:last-child {
      margin-right: 0;
    }

    .popup & {
      background-color: color.$bg-grey;

      width: 28px;
      height: 32px;
      font-size: font.$size-base;
    }
  }

  &__notation {
    margin-right: calc(#{spacing.$s2} * 0.5);
    color: var(--theme-font-color);

    .popup & {
      margin-left: calc(#{spacing.$s2} * 0.5);
      margin-right: spacing.$s2;
    }
  }
}
