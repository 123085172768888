// Variables
@use '../../../variable/breakpoint';
@use '../../../variable/color';
@use '../../../variable/font';
@use '../../../../stories/scss/settings/spacing';

.notification-bar + .discount-campaign {
  box-shadow: inset 0 1px 0 var(--theme-color-base);
}

.discount-campaign {
  cursor: pointer;
  background-color: color.$blue-lighter;

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    min-height: 36px;
    padding: 12px 0;

    @media screen and (min-width: breakpoint.$lg) {
      padding: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 spacing.$s10;

    &-text {
      color: color.$blue;
      font-size: font.$size-xxs;
      text-align: center;

      @media screen and (min-width: breakpoint.$lg) {
        font-size: font.$size-base;
      }

      a {
        color: color.$blue;
        text-decoration: underline;
      }
    }

    &-info {
      color: color.$blue;
      font-size: font.$size-icon;

      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      right: -4px;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 28px;

      @media screen and (min-width: breakpoint.$lg) {
        right: 0;
      }
    }
  }
}
