@use 'sass:color' as SassColor;

// website colors, for colors used in design system check storybook _settings.theme-ysp.scss
$base: #2B2118;
$sea: #6EA1AE;
$blue-lighter: #E2EBEF;
$blue: #3A7991;
$blue-dark: #306378;
$honey: #F09D51;
$yellow-light: #FFD7A6;
$yellow: #DEAC6E;
$yellow-dark: #C49862;
$yellow-darker: #9E7B4F;

$pink-gradient: linear-gradient(-90deg, rgba(222, 137, 110, 0.08) 0%, rgba(222, 137, 110, 0.20278) 100%);
$green-gradient: linear-gradient(-90deg, rgba(118, 137, 72, 0.04) 0%, rgba(118, 137, 72, 0.2) 100%);
$yellow-gradient: linear-gradient(-90deg, rgba(222, 172, 110, 0.06) 0%, rgba(222, 172, 110, 0.20278) 100%);
$blue-gradient: linear-gradient(-90deg, rgba(58, 121, 145, 0.04) 0%, rgba(58, 121, 145, 0.2) 100%);

$base-90: SassColor.adjust($base, $alpha: -0.1);
$base-80: SassColor.adjust($base, $alpha: -0.2);
$base-70: SassColor.adjust($base, $alpha: -0.3);
$base-60: SassColor.adjust($base, $alpha: -0.4);
$base-50: SassColor.adjust($base, $alpha: -0.5);
$base-40: SassColor.adjust($base, $alpha: -0.6);
$base-30: SassColor.adjust($base, $alpha: -0.7);
$base-20: SassColor.adjust($base, $alpha: -0.8);
$base-10: SassColor.adjust($base, $alpha: -0.9);
$bg-grey: SassColor.adjust($base, $alpha: -0.95);

