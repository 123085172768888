@use '../variable/breakpoint';
@use '../../stories/scss/settings/spacing';
@use '../variable/transition';

$container-xs: 320px !default;
$container-md: 1080px !default;
$container-lg: 1440px !default;
$container-xl: 1600px !default;

$padding: spacing.$s2 !default;

@mixin wrapper {
  max-width: $container-lg;
  min-width: $container-xs;
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding;
  padding-right: $padding;

  @media screen and (min-width: breakpoint.$md) {
    padding-left: spacing.$s6;
    padding-right: spacing.$s6;
  }

  @media screen and (min-width: breakpoint.$xl) {
    padding-left: spacing.$s10;
    padding-right: spacing.$s10;
  }
}

@mixin h-scrollable() {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin overlay-gradient(
  $_overlay-gradient-background: linear-gradient(to top, rgba(252, 248, 244, 1) 0%, rgba(252, 248, 244, 0) 100%),
  $_overlay-gradient-height: 100px
) {
  position: relative;
  &:after {
    padding-bottom: 0;
    display: block;
    width: 100%;
    height: $_overlay-gradient-height;
    content: '';
    background: $_overlay-gradient-background;
    position: absolute;
    bottom: 0;
    pointer-events: none;
  }
}

@mixin overlay-gradient-disable(
  $_overlay-gradient-disable-animation: false,
  $_overlay-gradient-disable-transition: transition.$slow
) {
  &:after {
    @if $_overlay-gradient-disable-animation {
      height: 0;
      transition: height $_overlay-gradient-disable-transition;
    }
    @if not $_overlay-gradient-disable-animation {
      content: '';
    }
  }
}

@mixin absolute-center-v(){
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

@mixin absolute-center-hv() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
