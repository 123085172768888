// Fonts
$system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
$primary: 'Museo Sans', 'Arial', $system !default;
$secondary: 'Garamond', 'Georgia', serif !default;
$icon: 'Font Awesome 6 Pro' !default;

// Weights
$weight-regular: normal !default;
$weight-600: 600 !default;
$weight-400: 400 !default;
$weight-bold: bold !default;

// Sizes
$size-xl: 38px;
$size-lg: 34px;
$size-md: 30px;
$size-sm: 26px;
$size-xs: 24px;
$size-xxs: 13px;
$size-base: 15px;

$size-icon: 16px;
$size-icon-md: 24px;
$size-icon-lg: 32px;
