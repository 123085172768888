@use '../../variable/breakpoint';
@use '../../variable/color';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: color.$base-40;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: flex;
  flex-direction: column;

  &--centered-content {
    align-items: center;
    justify-content: center;
  }

  &--visible-by-default {
    opacity: 1;
    visibility: visible;
  }

  &--non-blocking {
    overflow: auto;
    pointer-events: auto;
  }

  &--transparent {
    background-color: transparent;
    pointer-events: all;
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: breakpoint.$md) {
    & > div {
      flex-grow: 1;
    }
  }

  @media screen and (min-width: breakpoint.$md) {
    &:not(&--centered-content) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &.is-open {
    pointer-events: all;
    opacity: 1;
    visibility: visible;
    cursor: default;
  }
}
