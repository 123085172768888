@use 'sass:color' as SassColor;
@use '../../../variable/breakpoint';
@use '../../../variable/color';
@use '../../../../stories/scss/settings/spacing';
@use '../../../variable/font';

.notification-bar {
  &__main {
    background-color: SassColor.adjust(color.$yellow, $alpha: -0.75);
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    padding: 12px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 spacing.$s4;

    @media screen and (min-width: breakpoint.$lg) {
      align-items: center;
      flex-direction: row;
      padding: 0;
    }

    &-text {
      color: color.$yellow-darker;
      font-size: font.$size-xxs;

      @media screen and (min-width: breakpoint.$lg) {
        font-size: font.$size-base;
      }

      a {
        color: color.$yellow-darker;
      }
    }
  }

  &-close {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: -4px;

    @media screen and (min-width: breakpoint.$lg) {
      right: spacing.$s4;
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: transparent;
      border: 0;

      padding: 0;
      margin: 0;
      width: 28px;
      height: 28px;

      i {
        font-size: 16px;
        color: color.$yellow-darker;
      }

      &:active {
        border-radius: 50%;
      }
    }
  }
}
