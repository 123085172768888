@use '../../../variable/breakpoint';

.main-logo {
  width: 140px;
  height: auto;
  position: relative;
  top: -2px;

  @media screen and (min-width: breakpoint.$lg) {
    width: 150px;
  }

  &-wrapper {
    align-items: center;
    display: flex;
  }
}
