$s0: 0; // none
$s1: 4px; // root
$s2: 8px; // base
$s3: 12px; // xxs
$s4: 16px; // default
$s6: 24px; // xs
$s7: 28px;
$s8: 32px; // sm
$s10: 40px; // md
$s12: 48px;
$s18: 72px; // lg
$s28: 112px; // xl

$spacingsArray: (
  s0: $s0,
  s1: $s1,
  s2: $s2,
  s3: $s3,
  s4: $s4,
  s6: $s6,
  s8: $s8,
  s10: $s10,
  s18: $s18,
  s28: $s28
);
