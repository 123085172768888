@use '../../../../variable/breakpoint';
@use '../../../../variable/color';
@use '../../../../../stories/scss/settings/spacing';
@use '../../../../variable/transition';

.menu {
  max-width: 420px;
  transform: translate(-100%, 0);
  overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: var(--theme-color-background);

  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.1, 0, 0.1, 1);

  .wrapper {
    padding: 0;
  }

  &__backdrop {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color.$base-40;

    opacity: 0;
    visibility: hidden;

    transition: transition.$default opacity;
  }

  &.is-open {
    overflow-y: auto;
    transform: translate(0);

    & + .menu__backdrop {
      opacity: 1;
      visibility: visible;
    }
  }

  &.no-scroll {
    overflow: hidden;
  }
}
