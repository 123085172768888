@use '../../../variable/breakpoint';
@use '../../../variable/z-index';
@use '../../../../stories/scss/settings/spacing';
@use '../../../variable/shadow';

.usp-banner {
  background-color: var(--theme-color-secondary);
  position: relative;

  &-main {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    column-gap: spacing.$s8;
    text-align: center;

    &-item {
      position: absolute;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      padding: 0 spacing.$s2;

      @media screen and (min-width: breakpoint.$lg) {
        position: relative;
        opacity: 1;
        padding: 0;
      }

      &.is-active {
        @keyframes SlideInOutFade {
          0% {
            transform: translateY(100%);
            opacity: 0;
          }
          15%, 85% {
            transform: translateY(0);
            opacity: 1;
          }
          100% {
            transform: translateY(-100%);
            opacity: 0;
          }
        }

        & {
          animation: SlideInOutFade 4s infinite ease-in-out 0.2s;
        }

        @media screen and (min-width: breakpoint.$lg) {
          animation: none;
        }

        .ysp-collapse--out & {
          transform: translateY(0);
          animation: none;
          opacity: 1;
        }
      }

      &-name {
        font-family: var(--theme-font-family-primary);
        color: var(--theme-color-white);
        font-size: 12px;
        font-weight: 600;
      }
    }

    i {
      color: var(--theme-color-white);
      margin-left: auto;
    }
  }

  &-dropdown {
    background-color: var(--theme-color-background);
    color: var(--theme-font-color);
    position: absolute;
    inset: auto 0 auto 0;
    z-index: z-index.$top-5;
    box-shadow: shadow.$drop-darker-bottom;

    &-item {
      position: relative;
      padding: spacing.$s8 spacing.$s4;

      &:not(:first-child) {
        border-top: 1px solid var(--theme-color-base);
      }
    }
  }
}
