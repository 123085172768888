@use '../../../variable/breakpoint';
@use '../../../variable/color';
@use '../../../variable/font';
@use '../../../../stories/scss/settings/spacing';
@use '../../../variable/transition';
@use '../../../../scss/mixin/flexbox';

.cartBox {
  $cartBox: &;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  border-radius: var(--theme-border-radius-sm);
  background-color: transparent;
  transition: transition.$default background-color;

  &:hover {
    background-color: var(--theme-color-secondary-light);

    * {
      color: var(--theme-color-secondary);
    }

    #{$cartBox}__icon {
      #{$cartBox}__circle {
        border-color: var(--theme-color-secondary-light);

        &__numbers {
          i {
            color: var(--theme-color-white);
          }
        }
      }
    }
  }

  #{$cartBox}__icon {
    position: relative;

    &:not(:only-child) {
      margin-right: 2px;
    }

    #{$cartBox}__circle {
      @include flexbox.flex-align-justify($_flex__justify-content: center);

      position: absolute;
      left: -8px;
      bottom: -4px;

      border-radius: 100%;
      width: 16px;
      height: 16px;
      border: 2px solid var(--theme-color-background);
      background-color: var(--theme-color-primary);
      transition: transition.$default all;

      &--largeOrder {
        width: auto;
        padding: 0 4px;
        border-radius: 20px;
      }

      &[data-cart-amount='0'],
      &:not([data-cart-amount]) {
        display: none;
      }

      .account-login-btn & {
        display: flex;
      }

      &__numbers {
        color: var(--theme-color-white);
        font-weight: font.$weight-600;
        font-size: 8px;

        i {
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

